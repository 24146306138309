<template>
    <div class="app-container">
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="crud.toQuery"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="add()"
            >
              新增
            </el-button>
            <!-- <el-button
              class="filter-item"
              size="mini"
              type="success"
              icon="el-icon-edit"
            >
              修改
            </el-button>
            <el-button
              slot="reference"
              class="filter-item"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
              删除
            </el-button> -->
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="notice_ID"
          label="公告ID"
          min-width="80">
        </el-table-column>
        <el-table-column
          fixed
          prop="notice_TITLE"
          label="公告标题"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="is_VALID_NAME"
          label="是否有效"
          min-width="50">
        </el-table-column>
        <!-- <el-table-column
          prop="is_PROMPT_NAME"
          label="是否提示"
          min-width="50">
        </el-table-column> -->
        <!-- <el-table-column
          prop="pdf"
          label="文件路径"
          :show-overflow-tooltip="true"
          min-width="180">
        </el-table-column> -->
        <!-- <el-table-column
          label="内容"
          width="360">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>姓名: {{ scope.row.content }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.content }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作" align="center"
          min-width="180">
          <template slot-scope="scope">
            <el-button type="primary"  icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" ></el-button>
            <el-button type="danger"  icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-card>
          <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      <el-dialog append-to-body  title="终端公告" :visible.sync="addDialogVisible" width="35%" @close='resetForm' >
          <el-form ref="form" :rules="rules"  :model="form"  label-width="80px" class="demo-ruleForm">
            <div>
            <el-form-item label="标题" prop="notice_TITLE" >
              <el-col :span="11">
                <el-input v-model="form.notice_TITLE"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="是否有效" prop="is_VALID">
              <el-select v-model="form.is_VALID"  placeholder="请选择">
                <el-option
                  v-for="item in isOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否提示" prop="is_PROMPT">
              <el-select v-model="form.is_PROMPT"  placeholder="请选择">
                <el-option
                  v-for="item in isOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="文件选择" >
            <el-upload
              class="upload-demo"
              action="#"
              :headers="importHeaders"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="1"
              :on-change="onChange"
              :file-list="fileList"
              accept=".pdf"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">点击上传</el-button>
              <span style="margin-left:10px">只能上传pdf且图片内容只能二选一</span>
            </el-upload>
            <!-- <div  tabindex="0" class="el-upload el-upload--picture-card" :style="'width: '+width+'px;height: '+height+'px;'+'line-height:'+height+'px;'" @click="toSeleteMaterial">
              <i class="el-icon-plus" />
            </div> -->
            </el-form-item>
            </div>
            <el-form-item label="公告内容" prop="news_CONTENT">
              <quill-editor
              v-model="form.content"
              :options="editorOption"></quill-editor>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="resetForm()" >取消</el-button>
            <el-button v-if="isAdd == true" type="primary" @click="submitFrom('form')">确认</el-button>
            <el-button  v-if="isAdd == false" type="primary" @click="updateFrom('form')">修改</el-button>
          </div>
      </el-dialog>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      </el-card>
    </div>
</template>
<script>
import axios from 'axios'
var mytoken = window.sessionStorage.getItem('token')
export default {
  name: 'News',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/notice', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    companyCurrentChange (val) {
    },
    add () {
      this.addDialogVisible = true
      this.isAdd = true
    },
    handleEdit (index, row) {
      this.addDialogVisible = true
      this.form.notice_TITLE = row.notice_TITLE
      this.form.content = row.content
      this.form.is_VALID = row.is_VALID + ''
      this.form.is_PROMPT = row.is_PROMPT + ''
      this.form.notice_ID = row.notice_ID
      this.isAdd = false
    },
    resetForm () {
      this.addDialogVisible = false
      this.form.notice_TITLE = ''
      this.form.content = ''
      this.form.is_VALID = ''
      this.form.is_PROMPT = ''
      this.form.notice_ID = ''
    },
    submitFrom (form) {
      var isPass = false
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.fileList !== undefined && this.form.fileList.length > 0 && this.form.content !== '') {
            this.$message({
              message: '文件和公告内容只能选一个',
              type: 'warning'
            })
          } else {
            isPass = true
          }
        }
      })
      if (isPass) {
        const url = axios.defaults.baseURL
        const formData = new FormData()
        let path = ''
        // 不含图片时和含图片请求分开
        if (this.form.fileList !== undefined && this.form.fileList.length > 0) {
          path = '/upload/notice'
          formData.append('file', this.form.fileList[0].raw)
          formData.append('files', this.form.fileList[0].raw)
          formData.append('files', this.form.fileList[0].raw)
        } else {
          path = '/insert/notice'
        }
        formData.append('notice_TITLE', this.form.notice_TITLE)
        formData.append('content', this.form.content)
        formData.append('is_VALID', this.form.is_VALID)
        formData.append('is_PROMPT', this.form.is_PROMPT)
        axios.post(url + path, formData, { Authorization: mytoken, 'Content-Type': 'multipart/form-data' }
        ).then(res => {
          this.addDialogVisible = false
          this.getTableData()
        })
      }
    },
    async updateFrom (form) {
      var isPass = false
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.fileList !== undefined && this.form.fileList.length > 0 && this.form.content !== '') {
            this.$message({
              message: '文件和公告内容只能选一个',
              type: 'warning'
            })
          } else {
            isPass = true
          }
        }
      })
      if (isPass) {
        const url = axios.defaults.baseURL
        const formData = new FormData()
        let path = ''
        // 不含图片时和含图片请求分开
        if (this.form.fileList !== undefined && this.form.fileList.length > 0) {
          path = '/upload/update/notice'
          formData.append('file', this.form.fileList[0].raw)
          formData.append('files', this.form.fileList[0].raw)
          formData.append('files', this.form.fileList[0].raw)
        } else {
          path = '/update/notice'
        }
        formData.append('notice_TITLE', this.form.notice_TITLE)
        formData.append('content', this.form.content)
        formData.append('is_VALID', this.form.is_VALID)
        formData.append('is_PROMPT', this.form.is_PROMPT)
        formData.append('notice_ID', this.form.notice_ID)
        axios.post(url + path, formData, { Authorization: mytoken, 'Content-Type': 'multipart/form-data' }
        ).then(res => {
          this.addDialogVisible = false
          this.getTableData()
        })
      }
    },
    async deleteFrom (index, row) {
      await this.$http.post('delete/notice', row)
      this.getTableData()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    onChange (file, fileList) {
      this.form.name = file.name
      this.form.fileList = fileList
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file) {
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    }
  },
  props: {
    // 素材数据
    value: {
      type: Array,
      default () {
        return []
      }
    },
    // 素材类型
    type: {
      type: String
    },
    // 素材限制数量，默认5个
    num: {
      type: Number,
      default () {
        return 5
      }
    },
    // 宽度
    width: {
      type: Number,
      default () {
        return 150
      }
    },
    // 宽度
    height: {
      type: Number,
      default () {
        return 150
      }
    }
  },
  data () {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      totle: 0,
      form: {
        notice_TITLE: '',
        content: '',
        is_VALID: '',
        is_PROMPT: '',
        notice_ID: '',
        img: '',
        file: '',
        name: '',
        news_LEVEL: '',
        news_CONTENT: ''
      },
      isOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      tableData: [],
      addDialogVisible: false,
      all_company_options: [],
      company_options: [],
      editorOption: { // 定义富文本编辑器显示
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗、倾斜、下划线、删除线
            [{ header: 1 }, { header: 2 }], // 标题一、标题二
            [{ list: 'ordered' }, { list: 'bullet' }], // 列表
            [{ color: [] }, { background: [] }], // 字体颜色、背景颜色
            [{ align: [] }],
            ['image']
          ]
        }
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      isAdd: '',
      // fileList: [{
      //   name: 'food.jpeg', url: 'http://localhost:8001/crdc/static/images/1.jpg'
      // }]
      fileList: [],
      importHeaders: { Authorization: mytoken, 'Content-Type': 'multipart/form-data' },
      rules: {
        notice_TITLE: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        is_VALID: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        is_PROMPT: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      }
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
</style>
